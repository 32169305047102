import { BILLING_TYPES } from '@/utilities/constants'
import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'

const { PICK_RATE, MULTI_ITEM } = BILLING_TYPES

export function getHeaders(context) {
  if (context?.activeBillingType === MULTI_ITEM) return getMultiItemHeaders()
  else if (context?.activeBillingType === PICK_RATE) return getPickRateHeaders()
  else return []
}

function getFields() {
  const getText = (name, mod = 'billing') => title(i18n.t(mod + '.' + name))
  const makeField = (name, mod) => ({ text: getText(name, mod), sortable: false, value: name })
  return {
    billedQuantity: makeField('billedQuantity'),
    // TODO: check if this field is required => if not, delete as duplicates manifestUpdatedAt
    billingDate: {
      text: title(i18n.t('billing.billingDate')),
      sortable: false,
      value: 'manifestUpdatedAt',
    },
    clientOrderId: makeField('clientOrderId'),
    containerType: {
      text: title(i18n.t('billing.containerType')),
      sortable: false,
      value: 'containerTypeDescription',
    },
    description: makeField('description', 'common'),
    destinationCountry: makeField('destinationCountry'),
    fulfilmentFee: makeField('fulfilmentFee'),
    // TODO: check if this field is required => if not, delete as duplicates fulfilmentFee
    fulfilmentLineFee: makeField('fulfilmentLineFee'),
    hubooSku: makeField('hubooSku'),
    insertFee: makeField('insertFee'),
    // TODO: check if this should be called 'Sku Specific Fee'
    itemFees: { text: title(i18n.t('billing.itemFees')), sortable: true, value: 'skuSpecificFee' },
    itemWeight: makeField('itemWeight'),
    manifestUpdatedAt: {
      text: title(i18n.t('common.updatedAt')),
      sortable: false,
      value: 'manifestUpdatedAt',
    },
    originCountry: makeField('originCountry'),
    packagingFee: makeField('packagingFee'),
    // TODO: check if this field is required => if not, delete as duplicates packagingFee
    packagingLineFee: makeField('packagingLineFee'),
    perPackageFee: makeField('perPackageFee'),
    pickRateFee: makeField('pickRateFee'),
    protection: makeField('protection', 'common'),
    shippingPostcode: makeField('shippingPostcode'),
    salesValue: makeField('salesValue'),
    total: makeField('total', 'common'),
    weight: makeField('weight', 'common'),
  }
}

function getMultiItemHeaders() {
  const fields = getFields()
  return [
    fields.clientOrderId,
    fields.shippingPostcode,
    fields.originCountry,
    fields.destinationCountry,
    fields.containerType,
    fields.weight,
    fields.hubooSku,
    fields.description,
    fields.billedQuantity,
    fields.salesValue,
    fields.fulfilmentLineFee,
    fields.packagingLineFee,
    fields.perPackageFee,
    fields.insertFee,
    fields.protection,
    fields.itemFees,
    fields.total,
    fields.manifestUpdatedAt,
  ]
}

function getPickRateHeaders() {
  const fields = getFields()
  return [
    fields.billingDate,
    fields.clientOrderId,
    fields.shippingPostcode,
    fields.originCountry,
    fields.destinationCountry,
    fields.containerType,
    fields.weight,
    fields.description,
    fields.fulfilmentFee,
    fields.packagingFee,
    fields.perPackageFee,
    fields.insertFee,
    fields.pickRateFee,
    fields.protection,
    fields.itemFees,
    fields.total,
  ]
}
