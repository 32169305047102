<template>
  <huboo-full-screen-dialog
    :value="value"
    @input="$emit('input', $event)"
    :title="$t('billing.fulfilmentBilling') | title"
  >
    <huboo-list
      max-width="600px"
      class=" pa-4 mx-auto text-center"
      clickable
      @clicked="handleItemClicked"
      :items="items"
    ></huboo-list>
    <huboo-modal
      v-for="(item, index) in items"
      :key="'editModal' + index"
      maxWidth="600px"
      v-model="editModals[item.name]"
    >
      <v-card>
        <huboo-form
          :title="$t('common.edit') | title"
          @submit="onSubmit"
          :loading="loadingForm"
          :fields="[item]"
          v-if="editModals[item.name]"
        ></huboo-form>
      </v-card>
    </huboo-modal>
  </huboo-full-screen-dialog>
</template>

<script>
import { title } from '@/utilities/filters'
import { BILLING_TYPES } from '@/utilities/constants'

export const PICK_RATE_FIELDS = [
  'manifestUpdatedAt',
  'clientOrderId',
  'shippingPostcode',
  'originCountry',
  'destinationCountry',
  'containerTypeDescription',
  'weight',
  'description',
  'fulfilmentFee',
  'packagingFee',
  'perPackageFee',
  'insertFee',
  'pickRateFee',
  'protection',
  'skuSpecificFee',
  'total',
]

export const MULTI_ITEM_FIELDS = [
  'manifestUpdatedAt',
  'clientOrderId',
  'shippingPostcode',
  'originCountry',
  'destinationCountry',
  'containerTypeDescription',
  'weight',
  'description',
  'billedQuantity',
  'salesValue',
  'fulfilmentLineFee',
  'packagingLineFee',
  'perPackageFee',
  'insertFee',
  'protection',
  'skuSpecificFee',
  'total',
]

const ALL_FIELDS = PICK_RATE_FIELDS.concat(MULTI_ITEM_FIELDS)

const { PICK_RATE, MULTI_ITEM } = BILLING_TYPES

export default {
  name: 'FulfilmentBillingModal',
  components: {},
  props: {
    selected: Object,
    value: Boolean,
    activeBillingType: String,
  },
  data: () => ({
    editModals: ALL_FIELDS.reduce((a, v) => {
      a[v] = false
      return a
    }, {}),
  }),
  computed: {
    fields() {
      return {
        billedQuantity: { label: title(this.$t('billing.billedQuantity')) },
        clientOrderId: { label: title(this.$t('billing.clientOrderId')) },
        containerTypeDescription: { label: title(this.$t('billing.containerType')) },
        description: {
          label: title(this.$t('common.description')),
          textColor: this.selected?.descriptionHasError ? 'error' : null,
        },
        destinationCountry: { label: title(this.$t('billing.destinationCountry')) },
        fulfilmentFee: { label: title(this.$t('billing.fulfilmentFee')) },
        fulfilmentLineFee: { label: title(this.$t('billing.fulfilmentLineFee')) },
        insertFee: { label: title(this.$t('billing.insertFee')) },
        manifestUpdatedAt: { label: title(this.$t('billing.billingDate')) },
        originCountry: { label: title(this.$t('billing.originCountry')) },
        packagingFee: { label: title(this.$t('billing.packagingFee')) },
        packagingLineFee: { label: title(this.$t('billing.packagingLineFee')) },
        perPackageFee: { label: title(this.$t('billing.perPackageFee')) },
        pickRateFee: { label: title(this.$t('billing.pickRateFee')) },
        protection: { label: title(this.$t('common.protection')) },
        salesValue: { label: title(this.$t('common.salesValue')) },
        shippingPostcode: { label: title(this.$t('billing.shippingPostcode')) },
        skuSpecificFee: { label: title(this.$t('billing.itemFees')) },
        total: { label: title(this.$t('common.total')) },
        weight: { label: title(this.$t('common.weight')) },
      }
    },
    items() {
      return this.resolvedFields
        .map(f => ({ ...this.fields[f], name: f }))
        .map(f => ({
          ...f,
          errorMessages: this.$store.getters['core/validationError'](f.name),
          // TODO: disable global readonly attribute when editing allowed and
          // place in individual items above , e.g., id, hubooBox, etc.
          readonly: true,
          text: this.selected?.[f.name],
          title: f.label,
          value: Object.prototype.hasOwnProperty.call(f, 'value') || this.selected?.[f.name],
        }))
    },
    loadingForm() {
      return false
    },
    resolvedFields() {
      if (this.activeBillingType === PICK_RATE) return PICK_RATE_FIELDS
      else if (this.activeBillingType === MULTI_ITEM) return MULTI_ITEM_FIELDS
      else return []
    },
  },
  watch: {},
  created() {},
  methods: {
    handleItemClicked() {
      // this.editModals[e.name] = true
    },
    // onSubmit(e) {
    //   console.log('submitting...', e)
    // },
  },
}
</script>

<style lang="scss" scoped></style>
