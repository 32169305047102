<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('billing.fulfilmentBilling') | title"></huboo-page-heading>

    <huboo-table
      @row-clicked="onSelected"
      @search="handleSearch"
      @filter="handleFilter"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      item-class="class"
      v-bind="options"
      clickable
      :server-items-length="serverItemsLength"
      :loading="loading"
    >
      <template #prepend>
        <v-row>
          <v-col>
            <v-form>
              <huboo-radio
                @input="onBillingTypeRadioInput"
                :disabled="billingTypeRadioDisabled"
                :options="billingTypeRadioOptions"
                row
                :value="activeBillingType"
              ></huboo-radio>
            </v-form>
          </v-col>
          <v-col v-if="totalSum">
            <div class="caption text-right my-3">
              <span class="black--text text-h6 font-weight-black">{{
                $t('common.total') + ': ' + totalSum + ' ' + currency
              }}</span>
            </div>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :client="box.hubooBox.toString()"
        />
      </template>
      <template v-slot:filter>
        <huboo-date-range-picker
          @update="updateDateRange"
          modal-width="300px"
        ></huboo-date-range-picker>
      </template>
    </huboo-table>

    <fulfilment-billing-modal
      v-model="modalOpen"
      :selected="selected"
      :activeBillingType="activeBillingType"
    />
  </huboo-page>
</template>

<script>
import { title } from '@/utilities/filters'
import FulfilmentBillingModal from '@/views/pages/billing/fulfilmentBilling/FulfilmentBillingModal'
import { BILLING_TYPES } from '@/utilities/constants'
import cloneDeep from 'lodash/cloneDeep'
import { getHeaders } from '@/views/pages/billing/fulfilmentBilling/fulfilmentBillingHelpers'
import ClientMixin from '@/mixins/client'
import ExportButton from '@/components/pages/ExportButton.vue'

const { PICK_RATE, MULTI_ITEM } = BILLING_TYPES

const hasBilling = headers => !!headers?.data.length

export default {
  name: 'FulfilmentBilling',
  mixins: [ClientMixin],
  components: {
    'fulfilment-billing-modal': FulfilmentBillingModal,
    'export-button': ExportButton,
  },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    modalOpen: false,
    search: '',
    selected: null,
    selectedBillingType: null,
  }),
  computed: {
    activeBillingType() {
      const { hasBoth, hasMultiItemBillingOnly, hasPickRateBillingOnly } = this
      if (hasPickRateBillingOnly) return PICK_RATE
      else if (hasMultiItemBillingOnly) return MULTI_ITEM
      else if (hasBoth && this.selectedBillingType === PICK_RATE) return PICK_RATE
      else if (hasBoth && this.selectedBillingType === MULTI_ITEM) return MULTI_ITEM
      else return null
    },
    billingType() {
      const { billingType = MULTI_ITEM } = this.box || {}
      return billingType
    },
    billingTypeRadioOptions() {
      const { billingType = MULTI_ITEM } = this.box || {}
      const multiItemOption = { label: title(this.$t('billing.multiItem')), value: MULTI_ITEM }
      const pickRateOption = { label: title(this.$t('billing.pickRate')), value: PICK_RATE }
      if (billingType === MULTI_ITEM) return [multiItemOption, pickRateOption]
      else if (billingType === PICK_RATE) return [pickRateOption, multiItemOption]
      else return [multiItemOption, pickRateOption]
    },
    billingTypeRadioDisabled() {
      return !this.hasBoth
    },
    exportUrl() {
      return this.fulfilmentBilling?.exportUrl
    },
    exportName() {
      return this.$options.name
    },
    headers() {
      return getHeaders(this)
    },
    fulfilmentBilling() {
      const {
        hasBoth,
        hasMultiItemBillingOnly,
        hasPickRateBillingOnly,
        multiItemBilling,
        selectedBillingType,
        pickRateBilling,
      } = this
      if (hasMultiItemBillingOnly) return multiItemBilling
      if (hasPickRateBillingOnly) return pickRateBilling
      else if (hasBoth && selectedBillingType === MULTI_ITEM) return multiItemBilling
      else if (hasBoth && selectedBillingType === PICK_RATE) return pickRateBilling
      else return { data: [] }
    },
    hasBoth() {
      return hasBilling(this.multiItemBilling) && hasBilling(this.pickRateBilling)
    },
    hasMultiItemBillingOnly() {
      return hasBilling(this.multiItemBilling) && !hasBilling(this.pickRateBilling)
    },
    hasPickRateBillingOnly() {
      return !hasBilling(this.multiItemBilling) && hasBilling(this.pickRateBilling)
    },
    items() {
      return this.fulfilmentBilling.data.map(bh => {
        const v = { ...bh }
        const dhe = this.descriptionHasError(v.description)
        v.descriptionHasError = dhe
        v.class = dhe ? 'error--text' : null
        v.description = this.formatDescription(v.description)
        v.containerTypeDescription = this.getContainerTypeDescription(v.containerTypeCode)
        v.manifestUpdatedAt = this.formatDate(v.manifestUpdatedAt)
        return v
      })
    },
    loading() {
      const urls = [this.pickRateBillingUrl, this.multiItemBillingUrl]
      return urls.reduce((acc, url) => {
        return acc || this.$store.getters['core/apiEndpointIsLoading']({ method: 'GET', url })
      }, false)
    },
    meta() {
      return this.fulfilmentBilling?.meta || { total: this.items.length }
    },
    multiItemBilling() {
      return this.$store.getters['billing/getMultiItemHeaders']
    },
    multiItemBillingUrl() {
      return '/huboo-box/' + this.box?.hubooBox + '/billing-multi-item-headers'
    },
    pickRateBilling() {
      return this.$store.getters['billing/getPickRateHeaders']
    },
    pickRateBillingUrl() {
      return '/huboo-box/' + this.box?.hubooBox + '/billing-headers'
    },
    totalSum() {
      return this.fulfilmentBilling?.totalSum
    },
  },
  watch: {
    billingType: {
      handler(v) {
        this.selectedBillingType = v
        this.options.page = 1
      },
      immediate: true,
    },
    options: {
      deep: true,
      immeditate: true,
      handler() {
        this.fetch()
      },
    },
    selectedBillingType: function() {
      this.options.page = 1
      this.fetch()
    },
  },
  created() {
    this.$store.dispatch('billing/fetchContainerTypeCodes', { url: 'container-type-codes' })
  },
  methods: {
    fetch() {
      if (!this.loading) {
        const { options, orderBy, search, dateRange } = this
        const { page, itemsPerPage } = options
        const params = {}
        if (page) params.page = page
        if (itemsPerPage) params.itemsPerPage = itemsPerPage
        if (search) params.query = search
        if (dateRange.length === 2) params.manifest_updated_at = dateRange?.join(',')
        if (orderBy) params.orderBy = orderBy
        const multiItemOptions = { params: cloneDeep(params), url: this.multiItemBillingUrl }
        const pickRateOptions = { params: cloneDeep(params), url: this.pickRateBillingUrl }
        this.$store.dispatch('billing/fetchPickRateHeaders', pickRateOptions)
        this.$store.dispatch('billing/fetchMultiItemHeaders', multiItemOptions)
      }
    },
    formatDescription(d) {
      const msg =
        this.$t('billing.noPricingFound') + ' - ' + this.$t('billing.pleaseReviewFulfilmentPricing')
      return this.descriptionHasError(d) ? msg : d
    },
    getContainerTypeDescription(name) {
      return this.$store.getters['billing/getContainerTypeCodes']?.find(c => c.name === name)
        ?.description
    },
    onBillingTypeRadioInput(e) {
      this.selectedBillingType = e
    },
  },
}
</script>

<style lang="scss" scoped></style>
